import px1 from '../static/px1.jpg'
import px2 from '../static/px2.jpg'
import px3 from '../static/px3.jpg'
import px4 from '../static/px4.jpg'
import px5 from '../static/px5.jpg'
import px6 from '../static/px6.jpg'
import px7 from '../static/px7.jpg'
import px8 from '../static/px8.jpg'
import px9 from '../static/px9.jpg'
import px10 from '../static/px10.jpg'
import px11 from '../static/px11.jpg'

export default [px1, px2, px3, px4, px5, px6, px7, px8, px9, px10, px11]