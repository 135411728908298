import React, { useEffect, useRef, useState } from 'react';
import * as styles from '../Projects/styles/projectDetails.module.css';
import { graphql, Link } from 'gatsby';
import { motion } from 'framer-motion';
import images from '../../Images';
import Layout from '../components/Layout';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';

export default function ProjectDetails({ data }) {
  const {
    topic,
    subTopic,
    bannerHeader,
    bannerImg,
    client,
    cost,
    tags,
    stack,
    overview,
    categories,
    location,
    duration,
    team_member1,
    team_member2,
    team_member3,
    team_member4,
    team_member5,
    team_member6,
    team_member1_name,
    team_member2_name,
    team_member3_name,
    team_member4_name,
    team_member5_name,
    team_member6_name,
    job1,
    job2,
    job3,
    job4,
    job5,
    job6,
    id,
  } = data.projectDummyJson;

  const projects = data.allProjectDummyJson.nodes;

  const tl = gsap.timeline();
  gsap.registerPlugin(ScrollTrigger);

  const [off, setOff] = useState(0);
  const handleScroll = () => setOff(window.pageYOffset);

  //refs
  let projectDetailBanner = useRef(null);
  let projectDetailBannerHeader = useRef(null);
  let projectDetailBannerText = useRef(null);

  let projectDetailSection1 = useRef(null);
  let projectDetailSection2 = useRef(null);
  let projectDetailSection3 = useRef(null);
  let projectDetailSection4 = useRef(null);
  let projectDetailSection5 = useRef(null);

  const [width, setWidth] = useState(0);
  const [width1, setWidth1] = useState(0);
  const detailsCarousel = useRef(null);
  const detailsCarousel1 = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setWidth1(detailsCarousel1.current.scrollWidth - 1450); 
    setWidth(detailsCarousel.current.scrollWidth - 1300);
  }, []);

  useEffect(() => {
    tl.from(projectDetailBannerHeader, 1, {
      y: -50,
      ease: 'power4.out',
      delay: 0.2,
      opacity: 0,
    })

    .from(projectDetailBannerText, 0.6, {
      y: 50,
      ease: 'power4.out',
      delay: 0.1,
      opacity: 0,
    })

    .from(projectDetailSection1, 1, {
      y: 100,
      opacity: 0,
      ease: 'power3.easeOut',
      delay: 0.3,
      stagger: {
        amount: 0.4,
      },
      scrollTrigger: {
        trigger: projectDetailBanner,
        start: '50%',
        end: '100%',
        pinSpacing: false,
        scrub: true,
      },
    })
    .from(projectDetailSection2, 1, {
      y: 100,
      opacity: 0,
      ease: 'power3.easeOut',
      delay: 0.3,
      stagger: {
        amount: 0.4,
      },
      scrollTrigger: {
        trigger: projectDetailSection1,
        start: '1%',
        end: '100%',
        pinSpacing: false,
        scrub: true,
      },
    })
    .from(projectDetailSection4, 1, {
      y: 100,
      opacity: 0,
      ease: 'power3.easeOut',
      delay: 0.3,
      stagger: {
        amount: 0.4,
      },
      scrollTrigger: {
        trigger: projectDetailSection3,
        start: '50%',
        end: '100%',
        pinSpacing: false,
        scrub: true,
      },
    })
    .from(projectDetailSection5, 1, {
      y: 100,
      opacity: 0,
      ease: 'power3.easeOut',
      delay: 0.3,
      stagger: {
        amount: 0.4,
      },
      scrollTrigger: {
        trigger: projectDetailSection4,
        start: '1%',
        end: '100%',
        pinSpacing: false,
        scrub: true,
      },
    });
  }, []);

  return (
    <Layout>
      <div key={id}>
        <div
          className={styles.project_details_banner_wrapper}
          ref={(el) => {
            projectDetailBanner = el;
          }}>
          <div>
            <img
              src={bannerImg}
              alt=''
              className={styles.project_details_banner_img}
            />
          </div>
          <h3
            className={styles.project_details_banner_header}
            style={{ transform: `translateY(${off * -2.3}px)` }}
            ref={(el) => {
              projectDetailBannerHeader = el;
            }}>
            {bannerHeader}
          </h3>
          <div>
            <div
              className={styles.project_details_banner_texts}
              ref={(el) => {
                projectDetailBannerText = el;
              }}>
              <div style={{ transform: `translateY(${off * -2.1}px)` }}>
                <h4>client</h4>
                <p>{client}</p>
              </div>
              <div style={{ transform: `translateY(${off * -1.8}px)` }}>
                <h4>duration</h4>
                <p>{duration}</p>
              </div>
              <div style={{ transform: `translateY(${off * -1.6}px)` }}>
                <h4>categories</h4>
                <p>{categories}</p>
              </div>
              <div style={{ transform: `translateY(${off * -1.2}px)` }}>
                <h4>tags</h4>
                <p>{tags}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.project_details_section_wrapper}>
          <div
            className={styles.project_details_section_overview_inner}
            ref={(el) => {
              projectDetailSection1 = el;
            }}>
            <h4>Project Overview</h4>
            <p>{overview}</p>
          </div>
          <div
            className={styles.project_details_carousel_wrapper}
            ref={(el) => {
              projectDetailSection2 = el;
            }}>
            <motion.div
              className={styles.project_details_carousel}
              ref={detailsCarousel1}>
              <motion.div
                drag='x'
                dragConstraints={{ right: 0, left: -width1 }}
                className={styles.project_details_inner_carousel}>
                {images.map((image) => {
                  return (
                    <motion.div
                      className={styles.project_details_carousel_image}>
                      <img src={image} alt='images' />
                    </motion.div>
                  );
                })}
              </motion.div>
            </motion.div>
          </div>
          <div
            className={styles.project_details_section_details_wrapper}
            ref={(el) => {
              projectDetailSection3 = el;
            }}>
            <div className={styles.project_details_section_details_inner}>
              <h4>Project Details</h4>
              <div
                className={styles.project_details_section_details_inner_text}>
                <p>
                  <span>Location: </span>
                  {location}
                </p>
                <p>
                  <span>Client: </span>
                  {client}
                </p>
                <p>
                  <span>Duration: </span>
                  {duration}
                </p>
                <p>
                  <span>Technology used: </span>
                  {stack}
                </p>
                <p>
                  <span>Cost: </span>
                  {cost}
                </p>

                <span>Team </span>
                <div className={styles.project_details_section_team}>
                  <div className={styles.project_details_section_team_avatar}>
                    <div>
                      <img src={team_member1} alt='' />
                      <p>{team_member1_name}</p>
                      <span>{job1}</span>
                    </div>

                    <div>
                      <img src={team_member2} alt='' />
                      <p>{team_member2_name}</p>
                      <span>{job2}</span>
                    </div>

                    <div>
                      <img src={team_member3} alt='' />
                      <p>{team_member3_name}</p>
                      <span>{job3}</span>
                    </div>

                    <div>
                      <img src={team_member4} alt='' />
                      <p>{team_member4_name}</p>
                      <span>{job4}</span>
                    </div>
                  </div>
                  <div className={styles.project_details_section_team_avatar2}>
                    <div>
                      <img src={team_member5} alt='' />
                      <p>{team_member5_name}</p>
                      <span>{job5}</span>
                    </div>

                    <div>
                      <img src={team_member6} alt='' />
                      <p>{team_member6_name}</p>
                      <span>{job6}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={styles.project_details_showcase_video_wrapper}
            ref={(el) => {
              projectDetailSection4 = el;
            }}>
            <div>
              <img
                src='/Showcase.png'
                alt=''
                className={styles.project_details_showcase_video_img}
              />
            </div>
          </div>

          <div
            className={styles.project_details_section_showcase_wrapper}
            ref={(el) => {
              projectDetailSection5 = el;
            }}>
            <div className={styles.project_details_section_showcase_inner}>
              <h5>Latest Projects</h5>
              <Link to='/Projects/Project'>See all</Link>
            </div>
            <div key={id}>
              <motion.div
                className={styles.project_showcase_carousel}
                ref={detailsCarousel}>
                <motion.div
                  drag='x'
                  dragConstraints={{ right: 0, left: -width }}
                  className={styles.project_showcase_inner_carousel}>
                  {projects.map((data) => {
                    return (
                      <motion.div>
                        <div className={styles.project_showcase_carousel_image}>
                          <img src={data.img} alt='images' />
                        </div>
                        <div
                          className={
                            styles.project_showcase_carousel_image_content
                          }>
                          <span>{data.subTopic}</span>
                          <p>{data.topic}</p>
                          <Link
                            to={'/Projects/Project/' + data.slug}
                            className={styles.project_showcase_link}>
                            view project{' '}
                            <span>
                              {'>'}
                            </span>
                          </Link>
                        </div>
                      </motion.div>
                    );
                  })}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ProjectDetails($slug: String) {
    projectDummyJson(slug: { eq: $slug }) {
      topic
      subTopic
      img
      bannerHeader
      bannerImg
      client
      cost
      tags
      stack
      overview
      categories
      location
      duration
      team_member1
      team_member2
      team_member3
      team_member4
      team_member5
      team_member6
      team_member1_name
      team_member2_name
      team_member3_name
      team_member4_name
      team_member5_name
      team_member6_name
      job1
      job2
      job3
      job4
      job5
      job6
      id
    }
    allProjectDummyJson {
      nodes {
        id
        img
        topic
        subTopic
        slug
      }
    }
  }
`;
